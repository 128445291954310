import './index.scss';
import Swiper from 'swiper/swiper-bundle.min.js'
var swiper = new Swiper(".banner .swiper-container", {
  autoplay: true,
});

var teamSwiper = new Swiper(".team .mySwiper", {
  observer: true, //修改swiper自己或子元素时，自动初始化swiper
  observeParents: true, //修改swiper的父元素时，自动初始化swiper
  navigation: {
    nextEl: ".team .mySwiper .swiper-button-next",
    prevEl: ".team .mySwiper .swiper-button-prev",
  },
  breakpoints: {
    320: { //当屏幕宽度大于等于320
      slidesPerView: 1,
      spaceBetween: 10
    },
    776: { //当屏幕宽度大于等于776
      slidesPerView: 2,
      spaceBetween: 30
    },
    991: { //当屏幕宽度大于等于991
      slidesPerView: 3,
      spaceBetween: 30
    },
    1200: { //当屏幕宽度大于等于1200
      slidesPerView: 6,
      spaceBetween: 30
    }
  }
});

var teacherSwiper = new Swiper(".teacher .mySwiper", {
  observer: true, //修改swiper自己或子元素时，自动初始化swiper
  observeParents: true, //修改swiper的父元素时，自动初始化swiper
  navigation: {
    nextEl: ".teacher .swiper-button-next",
    prevEl: ".teacher .swiper-button-prev",
  },
  breakpoints: {
    320: { //当屏幕宽度大于等于320
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 30,
    },
    776: { //当屏幕宽度大于等于776
      slidesPerView: 4,
      slidesPerGroup: 4,
      spaceBetween: 50,
      grid: {
        fill: 'row',
        rows: 2,
      },
    },
  }
});
